import axios from 'axios';
import qs from 'qs';

import * as urls from '../../constants/urls';

export const getVacanciesHandler = ({ payload }) => axios.get(urls.VACANCIES.stringify(), {
    params: payload,
    paramsSerializer: qs.stringify
});
export const getVacancyHandler = ({ payload }) => axios.get(urls.VACANCY.stringify({ id: payload }));
export const getFacetsHandler = ({ payload }) => axios.get(urls.FACETS.stringify(), {
    params: payload,
    paramsSerializer: qs.stringify
});
