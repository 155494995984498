import axios from 'axios';
import { toLower } from 'ramda';

import { requestMapper, responseMapper } from './mappers';
import { mapObjectKeys } from './ramdaAdditions';

class HttpError {
    constructor(error) {
        const { response = {} } = error;

        this.origin = error;

        this.headers = mapObjectKeys(toLower, response.headers);
        this.data = response.data;
        this.status = response.status;
    }
}

const errorHandler = (error) => {
    const httpError = new HttpError(error);
    return Promise.reject(httpError);
};

export function provideRequestInterceptors() {
    axios.interceptors.request.use(
        (config) => requestMapper(config),
        errorHandler
    );
}

export function provideResponseInterceptors() {
    axios.interceptors.response.use(
        (response) => responseMapper(response),
        errorHandler
    );
}
