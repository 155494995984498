import 'ie-array-find-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import { render } from 'react-dom';
import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';

import './styles.css';

import App from './components/App';
import configureStore from './store/configureStore';
import { setBaseUrl } from './utils/http';
import { provideRequestInterceptors, provideResponseInterceptors } from './utils/httpInterceptors';
import { TRANSLATIONS } from './constants/translations';
import rollbarInit from './utils/rollbar';

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION
    };
}

setBaseUrl();
provideRequestInterceptors();
provideResponseInterceptors();

const store = configureStore();
const lang = localStorage.getItem('lang') || 'ru';

moment.locale(lang);
i18n
    .use(reactI18nextModule)
    .init({
        resources: TRANSLATIONS,
        lng: lang,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        }
    });

render(<App store={store} />, document.getElementById('root'));
