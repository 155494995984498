import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { pathOr, isEmpty } from 'ramda';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import qs from 'qs';
import { withI18n, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { getVacancies, getFacets } from '../actions/asyncActions';
import Filter from './Filter';
import { extendSearchPath } from '../utils/urlParams';
import DatePosted from './DatePosted';
import { withStateHandlers } from 'recompose';
import { RU_DOMAIN, EN_DOMAIN } from '../constants/translations';

const LINES_PER_PAGE = 20;

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword: this.getSearchPath().keyword || ''
        };
    }

    onChangeSearch = e => {
        this.setState({ keyword: e.target.value });
    }

    extendSearchPath = obj => {
        const { history, location, resetPage } = this.props;

        resetPage();
        history.replace(extendSearchPath(location, obj));
    }

    search = e => {
        this.extendSearchPath({ keyword: this.state.keyword });
        e.preventDefault();
    }

    clearAll = () => {
        const { history, location } = this.props;

        this.setState({ keyword: '' });
        history.replace(location.pathname);
    }

    getSearchPath = () => qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    loadMore = hasMore => {
        if (hasMore) {
            this.props.loadMore();
        }
    }

    render() {
        const { getVacancies: { data }, getFacets: { data: { officeClassifiers = [], datePosted }, meta }, t, loading, page } = this.props;
        const vacancies = pathOr([], ['items'], data);
        const count = pathOr(0, ['_meta', 'count'], data);
        const hasMore = !loading && LINES_PER_PAGE * page < count;

        return <Fragment>
            <Helmet>
                <title>{t('searchForJobs')}</title>
            </Helmet>
            <h2>{t('searchForJobs')}</h2>
            <div className="input-group">
                <form onSubmit={this.search}>
                    <span className="icon-search"></span>
                    <input
                        type="text"
                        value={this.state.keyword}
                        placeholder={t('search').toLowerCase()}
                        onChange={this.onChangeSearch} />
                    <button className="btn" type='submit'>{t('search')}</button>
                </form>
            </div>
            <div className="row">
                <div className="col-1">
                    <h2>{t('currentSearch')}</h2>
                    <button className="btn" onClick={this.clearAll}>{t('clearAll')}</button>
                    { meta.lastSucceedAt && datePosted && <DatePosted data={datePosted} extendSearchPath={this.extendSearchPath} />}
                    { officeClassifiers.map(classifier =>
                        <Filter key={classifier.id} extendSearchPath={this.extendSearchPath} {...classifier} />
                    )}
                </div>
                <div className="col-2">
                    <h2>
                        <Trans i18nKey='result' count={count}>{{count}}</Trans>
                    </h2>
                    <div className="list">
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={() => this.loadMore(hasMore)}
                            hasMore={hasMore}>
                            { vacancies.map(vacancy =>
                                <div className="item" key={vacancy.id}>
                                    <Link to={`/vacancy/${vacancy.id}`}>
                                        { vacancy.title }
                                    </Link>
                                    <div className="tags">
                                        {vacancy.city}{vacancy.external_code ? `   |   ${vacancy.external_code}` : ''}   |   {t('posted')} {moment(vacancy.published_at).fromNow()}
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

const getClassifier = classifier => {
    return classifier && !isEmpty(classifier) ? {
        office_classifier: JSON.parse(classifier)
    } : {};
}

export default withStateHandlers({
    loading: false,
    page: 1
}, {
    loadMore: ({ page }) => () => ({
        loading: true,
        page: page + 1
    }),
    endLoading: () => () => ({ loading: false }),
    resetPage: () => () => ({ page: 1 })
})(withI18n()(withAsyncActions({
    getVacancies: getVacancies
        .withPayload(({ location, page, lng }) => {
            const { classifier, keyword, datePosted } = qs.parse(location.search, { ignoreQueryPrefix: true });

            return {
                page,
                per_page: LINES_PER_PAGE,
                keyword,
                complex_filter: 1,
                date_posted: datePosted,
                ...getClassifier(classifier),
                domain: lng === 'ru' ? RU_DOMAIN : EN_DOMAIN
            };
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        .withErrorHandler(({ endLoading, loading }) => loading && endLoading())
        .withSuccessHandler(({ endLoading, loading }) => loading && endLoading()),
    getFacets: getFacets
        .withPayload(({ location, lng }) => {
            const { classifier, keyword, datePosted } = qs.parse(location.search, { ignoreQueryPrefix: true });

            return {
                keyword,
                complex_filter: 1,
                date_posted: datePosted,
                ...getClassifier(classifier),
                domain: lng === 'ru' ? RU_DOMAIN : EN_DOMAIN
            };
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(Main)));
