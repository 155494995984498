export const RU_DOMAIN = '3m.experium.ru';
export const EN_DOMAIN = '3m-eng.experium.ru';

export const TRANSLATIONS = {
    en: {
        translation: {
            'signin': 'Sign In',
            'searchForJobs': 'Search for Jobs',
            'search': 'Search',
            'currentSearch': 'Current Search',
            'clearAll': 'Clear All',
            'more': 'More',
            'less': 'Less',
            'posted': 'Posted',
            'result': '{{count}} Results',
            'follow': 'Follow Us',
            'apply': 'Apply',
            'applyLinkedIn': 'Apply with LinkedIn',
            'aboutUs': 'About Us',
            'similarJobs': 'Similar Jobs',
            '3mDescription': `
                <p>3M is science applied to life.</p>
                <p>At 3M, we are in your cell phone. We are in your car. We are at your doctor’s office.  You are never more than 10 feet from a 3M product.</p>
                <p>3M helps make it possible for many other companies to do what they do.</p>
                <p>At 3M, we produce a mind-bending 55,000 products.  Our technology expertise started over 100 years ago with ceramics, turning minerals into sandpaper. This expertise has grown to span 46 technology platforms, with thousands of products and applications.</p>
                <p>Our scientists are experts at combining our core technologies to invent a surprising range of products.  Our people and our products reshape the way we live our lives every day.</p>
                <p>From celebrating new ideas to uncovering fresh uses for tried and true products, 3M solves problems for people, for communities and customers around the world.</p>
            `,
            'day': '1 Day',
            'week': '1 Week',
            '2-4week': '2-4 Weeks',
            '5-8week': '5-8 Weeks',
            '9-12week': '9-12 Weeks',
            '12week': '12+ Weeks',
            'datePosted': 'Date posted',
        }
    },
    ru: {
        translation: {
            'signin': 'Войти',
            'searchForJobs': 'Поиск вакансий',
            'search': 'Поиск',
            'currentSearch': 'Текущий поиск',
            'clearAll': 'Очистить все',
            'more': 'Больше',
            'less': 'Меньше',
            'posted': 'Опубликовано',
            'result': 'Результатов: {{count}}',
            'follow': 'Подпишитесь на нашу страницу',
            'apply': 'Откликнуться на вакансию',
            'applyLinkedIn': 'Подать заявление через LinkedIn',
            'aboutUs': 'О нас',
            'similarJobs': 'Похожие вакансии',
            '3mDescription': `
                <p>3М - это наука, воплощенная в жизнь.</p>
                <p>Каждый день Вы множество раз встречаетесь с продукцией 3М -  в Вашем мобильном телефоне, в Вашем автомобиле, в кабинете Вашего врача. 3М всегда где-то рядом.</p>
                <p>3М помогает многим другим компаниям делать то, что они делают.</p>
                <p>В 3М мы производим 55 000 уникальных продуктов. Наши технологии начали свое развитие более 100 лет назад с превращения минералов в наждачную бумагу. Эта экспертиза со временем превратилась в 46 технологических платформ, которые формируют тысячи различных продуктов и сфер применения.</p>
                <p>Наши ученые - настоящие эксперты в том, как объединить различные технологии для создания новых решений. Наши люди и наши продукты каждый день меняют жизнь людей по всему миру.</p>
                <p>Мы находим различные сферы применения наших продуктов и тем самым решаем проблемы людей и наших клиентов по всему миру.</p>
            `,
            'day': '1 день',
            'week': '1 неделя',
            '2-4week': '2–4 недели',
            '5-8week': '5–8 недель',
            '9-12week': '9–12 недель',
            '12week': 'Более 12 недель',
            'datePosted': 'Дата публикации',
        }
    }
};
