import { pushRollbarError } from '../utils/rollbar';

export default () => next => action => {
    try {
        return next(action);
    } catch(error) {
        pushRollbarError(error);
        throw error;
    }
}
