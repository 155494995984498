import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import i18n from 'i18next';
import moment from 'moment';
import { withI18n } from 'react-i18next';

class LanguageSelect extends Component {
    state = {
        collapsed: false
    };

    setLang = lang => {
        i18n.changeLanguage(lang);
        moment.locale(lang);
        localStorage.setItem('lang', lang);
    };

    toggleCollapsed = () => this.setState(prev => ({
        collapsed: !prev.collapsed
    }));

    render() {
        const { lng } = this.props;
        const isEn = lng === 'en';
        const { collapsed } = this.state;

        return <Fragment>
            <div className={cx("btn-dropdown", { show: collapsed })} onClick={this.toggleCollapsed}>
                <button className="btn btn-select">{isEn ? 'English' : 'русский (Россия)'} <i className="icon-select"></i></button>
                <div className="container-select">
                    <ul className="list-select">
                        <li
                            className={cx({ active: lng === 'ru' })}
                            onClick={() => this.setLang('ru')}>
                            русский (Россия)
                        </li>
                        <li
                            className={cx({ active: isEn })}
                            onClick={() => this.setLang('en')}>
                            English
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>;
    }
}

export default withI18n()(LanguageSelect);
