import React, { Component } from 'react';
import { withI18n } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Main from './Main';
import Vacancy from './Vacancy';
import RedirectToMain from './RedirectToMain';
import LanguageSelect from './LanguageSelect';

class Routes extends Component {
    render() {
        return <div className="wrap">
            <header>
                <a href="/"><span className="logo"></span></a>
                <div className="btn-group">
                    {/*<button className="btn btn-primary">{this.props.t('signin')}</button>*/}
                    <LanguageSelect />
                </div>
            </header>
            <div className="banner">
                <h1>Here, you will work with the best and brightest</h1>
            </div>
            <div className="container">
                <Switch>
                    <Route path='/' exact component={Main} />
                    <Route path='/vacancy/:id' component={Vacancy} />
                    <Route component={RedirectToMain} />
                </Switch>
            </div>
            <div className="footer">
                <span className="logo-footer"></span>
                <div className="copy">© 2019 3M Russia, Inc. All rights reserved.</div>
            </div>
        </div>;
    }
}

export default withI18n()(withNamespaces()(Routes));
