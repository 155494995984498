import React, { Component } from 'react';
import { withI18n } from 'react-i18next';
import { filter, has, contains, append, without } from 'ramda';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { DATE_POSTED_TYPES } from '../constants/datePosted';

class DatePosted extends Component {
    static defaultProps = {
        data: {}
    };

    state = {
        collapsed: false,
        expanded: false
    };

    toggleCollapsed = () => this.setState(prev => ({
        collapsed: !prev.collapsed,
        expanded: false
    }));

    toggleExpanded = () => this.setState(prev => ({
        expanded: !prev.expanded
    }));

    getChecked = () => {
        const { datePosted = [] } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        return datePosted;
    }

    onChange = e => {
        const checked = this.getChecked();

        this.props.extendSearchPath({
            datePosted: e.target.checked ? append(e.target.value, checked) : without([e.target.value], checked)
        });
    }

    isChecked = type => {
        return contains(type, this.getChecked());
    }

    render() {
        const { data, t } = this.props;
        const { collapsed, expanded } = this.state;
        const options = filter(type => has(type, data), DATE_POSTED_TYPES);

        return <div className="group">
            <h2 className={cx("collapsed", { hidden: collapsed })} onClick={this.toggleCollapsed}>
                <button className="arrow" />{t('datePosted')}
            </h2>
            <ul className="list-check">
                { options.map(type =>
                    <li key={type} >
                        <div className="checkbox">
                            <input type="checkbox" id={type} value={type} onChange={this.onChange} checked={this.isChecked(type)} />
                            <label htmlFor={type}>{ t(type) } <span>({ data[type] })</span></label>
                        </div>
                    </li>
                )}
            </ul>
            { options.length > 5 &&
                <button
                    className={cx("more-expanded", { more: !expanded, less: expanded })}
                    onClick={this.toggleExpanded}>
                    <span>{t(expanded ? 'less' : 'more')}</span>
                </button>
            }
        </div>;
    }
}

export default withI18n()(withRouter(DatePosted));
