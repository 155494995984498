import { toSuccess, defaultKey } from 'react-async-client';
import { pathOr, concat } from 'ramda';

import { GET_VACANCIES } from '../constants/actionTypes';

const appender = (page = 1, payload, state) => ({
    ...payload,
    page,
    items: page > pathOr(1, [defaultKey, 'page'], state) ?
        concat(pathOr([], [defaultKey, 'items'], state), pathOr([], ['items'], payload)) :
        payload.items
});

export default (state, action, defaultReducer) => {
    switch (action.type) {
        case toSuccess(GET_VACANCIES):
            var { payload } = action.requestAction;

            return { ...state, [defaultKey]: appender(payload.page, action.payload, state) };
        default:
            return defaultReducer(state, action);
    }
};
