import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { SagaProvider } from 'react-async-client';
import { ConnectedRouter } from 'connected-react-router';

import { sagaMiddleware, history } from '../store/middlewares';
import Routes from './Routes';
import ScrollToTop from './ScrollToTop';

export default class App extends Component {
    render() {
        const { store } = this.props;

        return <SagaProvider sagaMiddleware={sagaMiddleware}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ScrollToTop>
                        <Routes />
                    </ScrollToTop>
                </ConnectedRouter>
            </Provider>
        </SagaProvider>;
    }
}
