import React, { Component, Fragment } from 'react';
import { withI18n } from 'react-i18next';
import cx from 'classnames';
import { take, find, propEq, dissoc, without, append, contains, isEmpty } from 'ramda';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

class Filter extends Component {
    state = {
        collapsed: false,
        expanded: false
    };

    toggleCollapsed = () => this.setState(prev => ({
        collapsed: !prev.collapsed,
        expanded: false
    }));

    toggleExpanded = () => this.setState(prev => ({
        expanded: !prev.expanded
    }));

    getItemTranslation = translations => {
        const { lng } = this.props;

        return find(propEq('lang', lng), translations) || {};
    }

    getValues = () => {
        const p = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        return p.classifier ? JSON.parse(p.classifier) : {};
    }

    onChange = e => {
        const { id } = this.props;
        let classifier = this.getValues();
        classifier = e.target.checked ? {
            ...classifier,
            [id]: append(e.target.value, classifier[id] || [])
        } : classifier[id].length < 2 ? dissoc(id, classifier) : {
            ...classifier,
            [id]: without(e.target.value, classifier[id])
        };

        this.props.extendSearchPath({ classifier: !isEmpty(classifier) ? JSON.stringify(classifier) : null })
    }

    isChecked = id => {
        const values = this.getValues();

        return contains(id.toString(), values[this.props.id] || []);
    }

    render() {
        const { t, options, translations } = this.props;
        const { collapsed, expanded } = this.state;
        const items = expanded ? options : take(5, options);
        const title = this.getItemTranslation(translations).name;

        return <div className="group">
            <h2 className={cx("collapsed", { hidden: collapsed })}  onClick={this.toggleCollapsed}>
                <button className="arrow" />{title}
            </h2>
            { !collapsed &&
                <Fragment>
                    <ul className="list-check">
                        { items.map(item =>
                            <li key={item.id}>
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        value={item.id}
                                        onChange={this.onChange}
                                        checked={this.isChecked(item.id)} />
                                    <label htmlFor={item.id}>
                                        {this.getItemTranslation(item.translations).value} <span>({item.count})</span>
                                    </label>
                                </div>
                            </li>
                        )}
                    </ul>
                    { options.length > 5 &&
                        <button
                            className={cx("more-expanded", { more: !expanded, less: expanded })}
                            onClick={this.toggleExpanded}>
                            <span>{t(expanded ? 'less' : 'more')}</span>
                        </button>
                    }
                </Fragment>
            }
        </div>;
    }
}

export default withI18n()(withRouter(Filter));
