import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();
export const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    middlewares.push(createLogger({ collapsed: true }));
}

if (process.env.NODE_ENV === 'production') {
    const rollbar = require('./rollbarMiddleware').default;
    middlewares.push(rollbar);
}

export default middlewares;
