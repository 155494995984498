import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import moment from 'moment';
import { map, filter } from 'ramda';
import Modal from 'react-responsive-modal';
import Helmet from 'react-helmet';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css'

import { getVacancy } from '../actions/asyncActions';
import { withI18n } from 'react-i18next';

class Vacancy extends Component {
    state = {
        opened: false
    };

    goBack = () => {
        return this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push('/');
    };

    openModal = () => this.setState({ opened: true });

    closeModal = () => this.setState({ opened: false });

    getFullPartTime = () => {
        const { getVacancy: { data: { office_classifiers }}, lng } = this.props;

        const values = map(item => item.value[lng] || item.value.ru,
            filter(({ title }) =>
                title.ru === 'Полная/частичная занятость' || title.en === 'Full/Part-time',
            office_classifiers || [])
        );

        return values.length ? values.filter(Boolean).reduce((res, cur, index) => `${res}${index ? ', ' : ''}${cur}`, '') : null;
    }

    getJobType = () => {
        const { getVacancy: { data: { office_classifiers }}, lng } = this.props;

        const values = map(item => item.value[lng] || item.value.ru,
            filter(({ title }) =>
                title.ru === 'Тип работы' || title.en === 'Job Type',
            office_classifiers || [])
        );

        return values.length ? values.filter(Boolean).reduce((res, cur, index) => `${res}${index ? ', ' : ''}${cur}`, '') : null;
    }

    render() {
        const { getVacancy: { data }, t, match } = this.props;
        const fullPartTime = this.getFullPartTime();
        const jobType = this.getJobType();

        return <Fragment>
            <Helmet>
                <title>{data.title || t('searchForJobs')}</title>
            </Helmet>
            <div className="vacancy">
                <h1>
                    <button className="back" onClick={this.goBack}><i className="icon-circle-left" /></button>
                    { data.title }
                </h1>
                <div className="row">
                    <div className="body-vacancy">
                        <div className="local">
                            <i className="icon-map"></i> { data.city }
                        </div>
                        <div className="controls">
                            <button onClick={this.openModal} className="btn btn-primary">{t('apply')}</button>
                        </div>
                        <div className="text-vacancy" dangerouslySetInnerHTML={{ __html: data.responsibilities }} />
                        <div className="controls">
                            <button onClick={this.openModal} className="btn btn-primary">{t('apply')}</button>
                        </div>
                    </div>
                    <div className="side-vacancy">
                        <ul className="list-tag">
                            <li><i className="icon-clock"></i>{t('posted')} {moment(data.published_at).fromNow()}</li>
                            { fullPartTime &&
                                <li><i className="icon-doc"></i>{ fullPartTime }</li>
                            }
                            { jobType &&
                                <li><i className="icon-doc"></i>{ jobType }</li>
                            }
                            { data.external_code && <li><i className="icon-bd"></i>{ data.external_code }</li> }
                        </ul>
                        <h2>{t('aboutUs')}</h2>
                        <span className="logo"></span>
                        <div dangerouslySetInnerHTML={{ __html: t('3mDescription') }} />
                    </div>
                </div>
                <div className="social">
                    <div className="follow">{t('follow')}</div>
                    <div className="follow-icon">
                        <Tooltip title='LinkedIn' animation='scale' distance={15}>
                            <a className="social-link in" href="https://www.linkedin.com/company/3m"><i className="icon-in"></i></a>
                        </Tooltip>
                        <Tooltip title='Facebook' animation='scale' distance={15}>
                            <a className="social-link fb" href="https://www.facebook.com/3M"><i className="icon-fb"></i></a>
                        </Tooltip>
                        <Tooltip title='Twitter' animation='scale' distance={15}>
                            <a className="social-link tw" href="https://twitter.com/3M"><i className="icon-tw"></i></a>
                        </Tooltip>
                        <Tooltip title='Youtube' animation='scale' distance={15}>
                            <a className="social-link youtube" href="https://www.youtube.com/user/3M"><i className="icon-youtube"></i></a>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Modal
                classNames={{
                    modal: 'modal-wrap'
                }}
                open={this.state.opened}
                onClose={this.closeModal}
                styles={{ modal: { height: 'calc(100% - 60px)' } }}>
                <iframe
                    title={match.params.id}
                    src={`${window.WEB_CAB_URL}/vacancy/${match.params.id}/response/add?viewingMode=iframe`}
                    width={800}
                    height='100%'
                    frameBorder={0} />
            </Modal>
        </Fragment>;
    }
}

export default withAsyncActions({
    getVacancy: getVacancy
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(withI18n()(Vacancy));
