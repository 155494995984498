import { combineReducers } from 'redux';
import { getAsyncReducers } from 'react-async-client';
import { connectRouter } from 'connected-react-router';

import { history } from '../store/middlewares';

export default () => combineReducers({
    ...getAsyncReducers(),
    router: connectRouter(history)
});
