import { createAsyncAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';
import appendReducer from '../reducers/appendReducer';

export const getVacancies = createAsyncAction(
    actions.GET_VACANCIES,
    handlers.getVacanciesHandler,
    {},
    undefined,
    appendReducer
);
export const getVacancy = createAsyncAction(actions.GET_VACANCY, handlers.getVacancyHandler);
export const getFacets = createAsyncAction(actions.GET_FACETS, handlers.getFacetsHandler);
